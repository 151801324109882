import axios from "axios";
import constant from "../../constant";
import router from "../../router";
const api = constant.api + "";
const url = constant.url + "";

const state = () => ({
  authenticated: false,
  authentications: [],
  user: {},
  usernaneError: "",
  passwordError: "",
  error: "",
  userRole: "",
});

const getters = {
  authenticated(state) {
    let user = localStorage.getItem("user-psm-goodgroupe-app-1");
    if (user) {
      state.user = user;
      return true;
    } else {
      state.user = {};
      return false;
    }
  },
  user: (state) => {
    let user = JSON.parse(localStorage.getItem("user-psm-goodgroupe-app-1"));
    if (user) {
      state.user = user;
      return state.user;
    } else {
      state.user = {};
      return state.user;
    }
  },

  getAuthData: (state) => {
    let user = JSON.parse(localStorage.getItem("user-psm-goodgroupe-app-1"));
    if (user) {
      state.user = user;
      return state.user;
    } else {
      state.user = {};
      return state.user;
    }
  },
  getUsernaneError: (state) => state.usernaneError,
  getPasswordError: (state) => state.passwordError,
  getError: (state) => state.error,
  getAllAuthentications: (state) => state.authentications,
  getUserRole: (state) =>
    localStorage.getItem("user-role-psm-goodgroupe-app-1"),
};

const actions = {
  async login({ commit, dispatch }, data) {
    await axios.get(url + "sanctum/csrf-cookie").then((p) => {
      axios
        .post(api + "auth/login", data)
        .then((response) => {
          let status = response.data.status;
          let message = response.data.message;

          if (status == "usernameError") {
            commit("setUsernameError", message);
            commit("setPasswordError", "");
          }
          if (status == "passwordError") {
            commit("setPasswordError", message);
            commit("setUsernameError", "");
          }
          if (status == "error") {
            commit("setError", message);
            commit("setUsernameError", "");
            commit("setPasswordError", "");
          }

          let user = response.data.user;

          if (status == "success") {
            commit("setToken", response.data.token);
            commit("setUser", response.data.user);
            commit("setAuthentificated", true);

            if (user.role != null) {
              commit("SET_USER_ROLE", user.role.fullName);
              if (user.role.fullName == "Ecommerce Admin") {
                 router.push({ name: "oujdafood-dashboard" });
                 setTimeout(function () {
                  window.location;
                }, 2000);
              } else if (user.role.fullName == "Admin") {
                router.push({ name: "dashboard" });
                setTimeout(function () {
                  window.location;
                }, 1000);
              } else if (user.role.fullName == "Magasinier") {
                router.push({ name: "dashboard" });
                setTimeout(function () {
                  window.location;
                }, 1000);
              } else if (user.role.fullName == "Caissier") {
                router.push({ name: "dashboard" });
                setTimeout(function () {
                  window.location;
                }, 1000);
              }
            }
            // router.push({ name: "dashboard" });

            setTimeout(function () {
              router.go({ name: "dashboard" });
              window.location;
            }, 1000);
          }
        })
        .catch(({ response: { data } }) => {
          // commit("SET_USER", {});
          // commit("SET_AUTHENTICATED", false);
        });
    });
  },
  async logout() {
    await localStorage.removeItem("user-psm-goodgroupe-app-1");
    await localStorage.removeItem("token-psm-goodgroupe-app-1");

    if (
      !localStorage.getItem("token-psm-goodgroupe-app-1") &&
      !localStorage.getItem("user-psm-goodgroupe-app-1")
    ) {
      await router.push({ name: "login" });
      await setTimeout(window.location, 1000);
    }
  },

  fetchAllAuthentications({ commit }) {
    axios
      .get(api + "auth")
      .then((response) => {
        let authentications = response.data;
        commit("setAllAuthentications", authentications);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },
};

const mutations = {
  SET_USER_ROLE(state, value) {
    state.userRole = value;
    localStorage.setItem("user-role-psm-goodgroupe-app-1", value);
  },
  setAuthentificated(state, value) {
    state.authenticated = value;
  },
  setUser(state, value) {
    state.user = value;
    console.log(value);
    localStorage.setItem("user-psm-goodgroupe-app-1", JSON.stringify(value));
    console.log(localStorage.getItem("user-psm-goodgroupe-app-1"));
  },
  setToken(state, value) {
    console.log(value);
    localStorage.setItem("token-psm-goodgroupe-app-1", JSON.stringify(value));
    console.log(localStorage.getItem("token-psm-goodgroupe-app-1"));
  },
  setUsernameError(state, value) {
    state.usernaneError = value;
  },
  setPasswordError(state, value) {
    state.passwordError = value;
  },
  setError(state, value) {
    state.error = value;
  },
  setAllAuthentications(state, value) {
    state.authentications = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
