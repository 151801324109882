import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "raw_materials";
// initial state
const state = () => ({
  all: [],
  raw_materials: [],
  items: [],
  raw_material: {},
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getItems: (state) => {
    return state.items;
  },
  getRawMaterial: (state) => {
    return state.raw_material;
  },

  getRawMaterials: (state) => {
    return state.raw_materials;
  },
  totalPriceTTCItems: (state) => {
    return state.items.reduce(
      (acc, val) => acc + val.quantity * val.priceTTC,
      0
    );
  },

  totalQuantityItems: (state) => {
    return state.items.reduce((acc, val) => acc + val.quantity, 0);
  },
  numberItems: (state) => {
    return state.items.length;
  },
};

// actions
const actions = {
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let raw_materials = response.data;
      commit("setRawMaterials", raw_materials);
    });
  },

  async getAllItems({ commit }) {
    await axios.get(api + "_items").then((response) => {
      commit("setItems", response.data);
    });
  },
  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let raw_material = response.data;
      commit("setRawMaterial", raw_material);
    });
  },
  async getCustomerRawMaterialsUnpaid({ commit }, customer_reference) {
    await axios.get(api + "_unpaid/" + customer_reference).then((response) => {
      let raw_materials = response.data;
      commit("setRawMaterials", raw_materials);
    });
  },
  async recalculationPrices({ commit, dispatch }, reference) {
    await axios
      .get(api + "_recalculation_prices/" + reference)
      .then((response) => {
        let raw_material = response.data.data;
        let message = response.data.message;
        let status = response.data.status;
        dispatch("notification/store", { status, message }, { root: true });
        commit("setRawMaterial", raw_material);
      });
  },

  async storeItem({ commit, dispatch }, data) {
    await axios.post(api + "_items", data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;

      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        commit("setItems", response.data.data);
      }
    });
  },

  async deleteItem({ dispatch }, id) {
    await axios.delete(api + "/" + id).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      
      dispatch("notification/store", { status, message }, { root: true });
    });
  },

  async store({ commit, dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        commit("setRawMaterial", response.data.data);
      }
    });
  },

  async update({ commit }, data) {
    await axios
      .put(api + "/" + data.reference, data.raw_material)
      .then((response) => {
        let message = response.data.message;
        let status = response.data.status;
        let raw_material = response.data.data;
        this._vm.$toasted.show(message, {
          type: status,
        });

        if (status == "success") {
          commit("setRawMaterial", raw_material);
          router.push({
            name: "profile-raw_material",
            params: {
              reference: raw_material.reference,
            },
          });
        }
      });
  },
};

// mutations
const mutations = {
  setRawMaterials(state, raw_materials) {
    state.all = raw_materials;
    state.raw_materials = raw_materials;
  },

  setItems(state, items) {
    state.items = items;
  },
  setRawMaterial(state, raw_material) {
    state.raw_material = raw_material;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
