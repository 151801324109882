import axios from "axios";
import router from "./router";


// Add this code after importing Axios and Vue Router

// Create a request interceptor
axios.interceptors.request.use(
  config => {
    // Modify the request config if needed
    // For example, you can add headers or authentication tokens

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// Create a response interceptor
axios.interceptors.response.use(
  response => {
    // Handle successful responses
    return response
  },
  error => {
    // Handle error responses
    if (
      error.response &&
      error.response.data &&
      error.response.data.message === 'Unauthenticated.'
    ) {
      router.push({ name: 'login' }) // Redirect to the login page
    }

    return Promise.reject(error)
  }
)
