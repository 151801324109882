import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "slaughters";
// initial state
const state = () => ({
  all: [],
  slaughters: [],
  items: [],
  slaughter: {},
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getItems: (state) => {
    return state.items;
  },
  getSlaughter: (state) => {
    return state.slaughter;
  },

  getSlaughters: (state) => {
    return state.slaughters;
  },
  totalPriceTTCItems: (state) => {
    return state.items.reduce(
      (acc, val) => acc + val.quantity * val.priceTTC,
      0
    );
  },

  totalQuantityItems: (state) => {
    return state.items.reduce((acc, val) => acc + val.quantity, 0);
  },
  numberItems: (state) => {
    return state.items.length;
  },
};

// actions
const actions = {
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let slaughters = response.data;
      commit("setSlaughters", slaughters);
    });
  },

  async getAllItems({ commit }) {
    await axios.get(api + "_items").then((response) => {
      commit("setItems", response.data);
    });
  },
  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let slaughter = response.data;
      commit("setSlaughter", slaughter);
    });
  },
  async getCustomerSlaughtersUnpaid({ commit }, customer_reference) {
    await axios.get(api + "_unpaid/" + customer_reference).then((response) => {
      let slaughters = response.data;
      commit("setSlaughters", slaughters);
    });
  },
  async recalculationPrices({ commit, dispatch }, reference) {
    await axios
      .get(api + "_recalculation_prices/" + reference)
      .then((response) => {
        let slaughter = response.data.data;
        let message = response.data.message;
        let status = response.data.status;
        dispatch("notification/store", { status, message }, { root: true });
        commit("setSlaughter", slaughter);
      });
  },

  async storeItem({ commit, dispatch }, data) {
    await axios.post(api + "_items", data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;

      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        commit("setItems", response.data.data);
      }
    });
  },

  async store({ commit, dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        commit("setSlaughter", response.data.data);
      }
    });
  },

  async update({ commit }, data) {
    await axios
      .put(api + "/" + data.reference, data.slaughter)
      .then((response) => {
        let message = response.data.message;
        let status = response.data.status;
        let slaughter = response.data.data;
        this._vm.$toasted.show(message, {
          type: status,
        });

        if (status == "success") {
          commit("setSlaughter", slaughter);
          router.push({
            name: "profile-slaughter",
            params: {
              reference: slaughter.reference,
            },
          });
        }
      });
  },
};

// mutations
const mutations = {
  setSlaughters(state, slaughters) {
    state.all = slaughters;
    state.slaughters = slaughters;
  },

  setItems(state, items) {
    state.items = items;
  },
  setSlaughter(state, slaughter) {
    state.slaughter = slaughter;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
