<template>
  <div id="modal" v-if="showModal" class="rounded">
    <div class="p-2">
      <div class="row align-items-stratch">
        <div class="col-8 my-3">
          <label for="">Catégorie</label>
          <select class="form-select" v-model="item.section_reference">
            <option
              v-for="(section, index) in sections"
              :key="index++"
              :value="section.reference"
            >
              {{ section.fullName }}
            </option>
          </select>
        </div>

        <div class="col-4 my-3">
          <label for=""> Code </label>
          <input type="text" v-model="item.code" class="form-control" />
        </div>

        <div class="col-12 my-3">
          <label for="">Désignation</label>
          <input type="text" v-model="item.fullName" class="form-control" />
        </div>
      </div>

      <div class="row">
        <div class="col my-3">
          <label for=""> Prix TTC</label>
          <input type="text" v-model="item.priceTTC" class="form-control" />
        </div>

        <div class="col my-3">
          <label for="">Unité </label>
          <select class="form-select" v-model="item.measuring">
            <option value="KG">KG</option>
            <option value="U">Unité</option>
            <option value="L">Litre</option>
            <option value="M">Mètre</option>
          </select>
        </div>

        <div class="col my-3">
          <label for=""> TVA </label>
          <select class="form-select" v-model="item.tva">
            <option value="0">0 %</option>
            <option value="20" :selected="true">20 %</option>
            <option value="15">15 %</option>
            <option value="10">10 %</option>
            <option value="7">7 %</option>
            <option value="5">5 %</option>
            <option value="2.5">2.5 %</option>
          </select>
        </div>

        <div class="col-12 my-3">
          <label for="">Description</label>
          <textarea v-model="item.description" class="form-control"></textarea>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col">
          <button class="btn btn-secondary" @click="showModal = !showModal">
            <i class="bi bi-check-square-fill"></i> Fermer
          </button>
        </div>
        <div class="col-auto">
          <button class="btn btn-success" @click="save(item)">
            <i class="bi bi-check-square-fill"></i> Enregistrer
          </button>
        </div>
        <div class="col-auto">
          <button class="btn btn-primary" @click="save(item)">
            <i class="bi bi-check-square-fill"></i> Enregistrer & Contenue
            l'achat
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    showModal: { type: Boolean, default: false },
  },
  data() {
    return {
      item: {},
      file: null,
      imageUrl: "",
    };
  },
  computed: {
    ...mapGetters("section", {
      sections: "getAll",
    }),
  },
  methods: {
    async save(data) {
      let formData = new FormData();

      formData.append("image", this.file);
      formData.append("fullName", data.fullName);
      if (data.description) formData.append("description", data.description);
      if (data.tva) formData.append("tva", data.tva);
      if (data.priceTTC) formData.append("priceTTC", data.priceTTC);
      if (data.measuring) formData.append("measuring", data.measuring);
      formData.append("section_reference", data.section_reference);

      await this.$store.dispatch("item/storeAndRouteToPurchase", formData);
      await this.$store.dispatch("item/getAll");
      await this.$store.dispatch("stock/getAll");
      await this.$store.dispatch("stock/fetchStockFullNames");
      await setInterval((this.item = {}), 1000);
      await (this.showModal = false);
    },

    async onFileChange(event) {
      // this.file = event.target.files[0];
      const input = document.createElement("input");
      input.type = "file";
      input.style.display = "none";

      input.addEventListener("change", (event) => {
        this.file = event.target.files[0];

        // Show the image
        this.imageUrl = URL.createObjectURL(this.file);
      });

      document.body.appendChild(input);
      input.click();
      document.body.removeChild(input);
      // Show the image
      this.imageUrl = URL.createObjectURL(this.file);
    },
  },
  beforeMount() {
    this.$store.dispatch("section/getAll");
  },
};
</script>

<style scoped>
#modal {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
  position: absolute;
  z-index: 1245603;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* opacity: .2; */
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
#modal > div {
  /* text-align: center; */
  background-color: #fff;
  left: auto;
  right: auto;
  width: 750px;
  height: 550px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  padding: 1rem !important;
}
</style>
