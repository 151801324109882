const state = () => ({
  alert: {
    message: "",
    // message: "Rien à signaler, vous pouvez continuer.",
    errorMessages: [],
  },
  downloading: {
    status: false,
    message: "",
  },
});

const getters = {
  getAlert: (state) => {
    return state.alert;
  },

  getDownloading: (state) => {
    return state.downloading;
  },
};

const actions = {
  async store({ commit }, data) {
    // this._vm.$notify("text");

    commit("setAlert", data);
    setTimeout(function () {
      commit("setAlert", {
        message: "",
        // message: "Rien à signaler, vous pouvez continuer.",
        errorMessages: [],
      });
    }, 10000);
  },

  async storeDownloading({ commit }, data) {
    console.log(data);

    commit("setDownloading", data);
    setTimeout(function () {
      commit("setDownloading", {
        message: "",
        // message: "Rien à signaler, vous pouvez continuer.",
      });
    }, 5000);
  },
};

const mutations = {
  async setAlert(state, data) {
    await (state.alert = data);
  },

  async setDownloading(state, data) {
    await (state.downloading = data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
