import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "deposits";
// initial state
const state = () => ({
  all: [],
  deposits: [],
  temperatures: [],
  deposit: {},
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getDeposit: (state) => {
    return state.deposit;
  },
  getDeposits: (state) => {
    return state.deposits;
  },
  getTemperatures: (state) => {
    return state.temperatures;
  },
};

// actions
const actions = {
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let deposits = response.data;
      commit("setDeposits", deposits);
    });
  },
  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let deposit = response.data;
      commit("setDeposit", deposit);
    });
  },

  async store({ commit, dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        router.push({ name: "deposits-index" });
      }
    });
  },

  async storeTemperature({ commit, dispatch }, data) {
    await axios.post(api + "_temperatures", data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      let data = response.data.data;
      dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        commit("setDeposit", data);
      }
    });
  },

  async update({ commit }, data) {
    await axios
      .put(api + "/" + data.reference, data.deposit)
      .then((response) => {
        let message = response.data.message;
        let status = response.data.status;
        let deposit = response.data.data;
        this._vm.$toasted.show(message, {
          type: status,
        });

        if (status == "success") {
          commit("setDeposit", deposit);
          router.push({
            name: "profile-deposit",
            params: {
              reference: deposit.reference,
            },
          });
        }
      });
  },
};

// mutations
const mutations = {
  setDeposits(state, deposits) {
    state.all = deposits;
    state.deposits = deposits;
  },
  setTemperatures(state, temperatures) {
    state.all = temperatures;
    state.temperatures = temperatures;
  },
  setDeposit(state, deposit) {
    state.deposit = deposit;
  },
  async search(state, value) {
    value = value.charAt(0).toUpperCase() + value.slice(1);
    console.log(value);
    state.deposits = state.all.filter(function (deposit) {
      return (
        deposit.firstName.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        deposit.lastName.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        deposit.phone.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        depositrouter
          .push({ name: "" })
          .reference.toUpperCase()
          .indexOf(value.toUpperCase()) > -1
      );
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
