import constant from "../../constant";
import axios from "axios";
const api = constant.api + "backup";
// initial state
const state = () => ({});

// getters
const getters = {};

// actions
const actions = {
  async run({ dispatch }, reference) {
    try {
      dispatch(
        "notification/storeDownloading",
        {
          status: "success",
          message:
            "Votre backup est en cours de téléchargement, veuillez patienter...",
        },
        { root: true }
      );
      const response = await axios
        .get(api + "/run",{})
        .then((response) => {
          // const url = window.URL.createObjectURL(new Blob([response.data]));
          // const link = document.createElement("a");
          // link.href = url;
          // link.setAttribute("download", "Cheque.pdf");
          // link.click();
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  },
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
