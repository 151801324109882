import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "coldrooms";
// initial state
const state = () => ({
  all: [],
  coldrooms: [],
  temperatures: [],
  coldroom: {},
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getColdroom: (state) => {
    return state.coldroom;
  },
  getColdrooms: (state) => {
    return state.coldrooms;
  },
  getTemperatures: (state) => {
    return state.temperatures;
  },
};

// actions
const actions = {
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let coldrooms = response.data;
      commit("setColdrooms", coldrooms);
    });
  },
  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let coldroom = response.data;
      commit("setColdroom", coldroom);
    });
  },

  async store({ commit, dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        commit("setColdroom", coldroom);
      }
    });
  },

  async storeTemperature({ commit, dispatch }, data) {
    await axios.post(api + "_temperatures", data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      let data = response.data.data;
      dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        commit("setColdroom", data);
      }
    });
  },

  async update({ commit }, data) {
    await axios
      .put(api + "/" + data.reference, data.coldroom)
      .then((response) => {
        let message = response.data.message;
        let status = response.data.status;
        let coldroom = response.data.data;
        this._vm.$toasted.show(message, {
          type: status,
        });

        if (status == "success") {
          commit("setColdroom", coldroom);
          router.push({
            name: "profile-coldroom",
            params: {
              reference: coldroom.reference,
            },
          });
        }
      });
  },
};

// mutations
const mutations = {
  setColdrooms(state, coldrooms) {
    state.all = coldrooms;
    state.coldrooms = coldrooms;
  },
  setTemperatures(state, temperatures) {
    state.all = temperatures;
    state.temperatures = temperatures;
  },
  setColdroom(state, coldroom) {
    state.coldroom = coldroom;
  },
  async search(state, value) {
    value = value.charAt(0).toUpperCase() + value.slice(1);
    console.log(value);
    state.coldrooms = state.all.filter(function (coldroom) {
      return (
        coldroom.firstName.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        coldroom.lastName.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        coldroom.phone.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        coldroom.reference.toUpperCase().indexOf(value.toUpperCase()) > -1
      );
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
