import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "societies";
// initial state
const state = () => ({
  all: [],
  societies: [],
  society: {},
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getSociety: (state) => {
    return state.society;
  },

  getSocieties: (state) => {
    return state.societies;
  },
};

// actions
const actions = {
  async fetchSocieties({ commit }) {
    await axios.get(api).then((response) => {
      let societies = response.data;
      commit("setSocieties", societies);
    });
  },
  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let society = response.data;
      commit("setSociety", society);
    });
  },

  async store({ commit, dispatch }, data) {
    await axios
      .post(api, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        let message = response.data.message;
        let status = response.data.status;
        dispatch("notification/store", { status, message }, { root: true });
        if (status == "success") router.push({ name: "societies-index" });
      });
  },

  async update({ dispatch }, data) {
    console.log(data);
    await axios
      .put(api + "/" + data.reference, data.formData)
      .then((response) => {
        let message = response.data.message;
        let status = response.data.status;
        dispatch("notification/store", { status, message }, { root: true });

        if (status == "success") {
          router.push({
            name: "societies-index",
          });
        }
      });
  },

  async destroy({ commit, dispatch }, reference) {
    await axios.delete(api + "/" + reference).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      let societies = response.data.data;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") commit("setSocieties", societies);
    });
  },
};

// mutations
const mutations = {
  setSocieties(state, societies) {
    state.all = societies;
    state.societies = societies;
  },
  setSociety(state, society) {
    state.society = society;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
