import constant from "../../constant";
import axios from "axios";
import router from "../../router";
import FileDownloadService from "@/services/FileDownloadService";

const api = constant.api + "stocks";

// initial state
const state = () => ({
  all: [],
  stocks: [],
  out_stocks: [],
  stock: {},
  stockFullNames: [],
  stockHistories: [],
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getStockFullNames: (state) => state.stockFullNames,
  getStockHistories: (state) => state.stockHistories,
  getStock: (state) => {
    return state.stock;
  },
  getTotalQuantity: (state) =>
    state.stocks.reduce((acc, val) => acc + parseFloat(val.quantity), 0),

  getTotalPurchasePriceTTC: (state) =>
    state.stocks.reduce(
      (acc, val) => acc + parseFloat(val.purchasePriceTTC),
      0
    ),

  getStocks: (state) => {
    return state.stocks;
  },
  getChartData: (state) => {
    return {
      labels: state.stocks.map(({ item }) => item.fullName),
      datasets: [
        {
          data: state.stocks.map(({ quantity }) => quantity),
          backgroundColor: state.stocks.map(function (item) {
            var r = Math.floor(Math.random() * 256); // Random value between 0 and 255
            var g = Math.floor(Math.random() * 256);
            var b = Math.floor(Math.random() * 256);

            // Convert the RGB values to hexadecimal format
            var rHex = r.toString(16).padStart(2, "0"); // Convert to two-digit hex
            var gHex = g.toString(16).padStart(2, "0");
            var bHex = b.toString(16).padStart(2, "0");

            // Combine the hexadecimal values to form the color code
            var colorCode = "#" + rHex + gHex + bHex;

            return colorCode;
          }),
        },
      ],
    };
  },
  getOutStocks: (state) => {
    return state.out_stocks;
  },

  getRandomColorCode() {
    // Generate random values for the RGB components
    var r = Math.floor(Math.random() * 256); // Random value between 0 and 255
    var g = Math.floor(Math.random() * 256);
    var b = Math.floor(Math.random() * 256);

    // Convert the RGB values to hexadecimal format
    var rHex = r.toString(16).padStart(2, "0"); // Convert to two-digit hex
    var gHex = g.toString(16).padStart(2, "0");
    var bHex = b.toString(16).padStart(2, "0");

    // Combine the hexadecimal values to form the color code
    var colorCode = "#" + rHex + gHex + bHex;

    return colorCode;
  },
};

// actions
const actions = {
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let stocks = response.data;
      commit("setStocks", stocks);
    });
  },
  async fetchStockFullNames({ commit }) {
    await axios.get(api + "_grouped").then((response) => {
      let data = response.data;
      commit("SET_SROCK_FULLNAMES", data);
    });
  },

  async fetchStockHistories({ commit }, stockFullName) {
    await axios.get(api + "_histories").then((response) => {
      let data = response.data;
      commit("SET_SROCK_HISTORIES", data);
    });
  },

  async filterStockHistories({ commit }, filter) {
    await axios.post(api + "_histories_filter", filter).then((response) => {
      let data = response.data;
      commit("SET_SROCK_HISTORIES", data);
    });
  },
  async getAllOut({ commit }) {
    await axios.get(api + "_out").then((response) => {
      let out_stocks = response.data;
      commit("setOutStocks", out_stocks);
    });
  },

  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let stock = response.data;
      commit("setStock", stock);
    });
  },

  async store({ dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;

      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        router.push({
          name: "stocks-index",
        });
      }
    });
  },

  async update({ dispatch }, data) {
    await axios.put(api + "/" + data.reference, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      if (status == "success") {
        router.push({
          name: "stocks-index",
        });
      }
      dispatch("notification/store", { status, message }, { root: true });
    });
  },

  async delete({ dispatch }, data) {
    await axios.delete(api + "/" + data.reference).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      if (status == "success") {
        router.push({
          name: "stocks-index",
        });
      }
      dispatch("notification/store", { status, message }, { root: true });
    });
  },

  async print({ dispatch }, data) {
    try {
      dispatch(
        "notification/storeDownloading",
        {
          status: "success",
          message:
            "La fiche de stock actuelle est en téléchargement, Veuillez patienter...",
        },
        { root: true }
      );

      const response = await axios.post(api + "_print_page", data, {
        responseType: "json", // Set the response type to 'json'
      });

      const binaryData = atob(response.data.output);
      const bytes = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        bytes[i] = binaryData.charCodeAt(i);
      }
      const blob = new Blob([bytes], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", response.data.filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  },

  async filter({ commit }, data) {
    try {
      await axios
        .post(api + "_filter", data)
        .then((response) => {
          let stocks = response.data;
          commit("setStocks", stocks);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  },
  async transferQuantity({ dispatch }, data) {
    try {
      // Perform an API request to transfer the quantity
      const response = await axios.post(api + "_transfer_quantity", data);

      let message = response.data.message;
      let status = response.data.status;

      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        router.push({
          name: "stocks-index",
        });
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async sendByEmail({ dispatch }) {
    try {
      dispatch(
        "notification/storeDownloading",
        {
          status: "success",
          message: "Envoi de la fiche de stock par email en cours...",
        },
        { root: true }
      );

      const response = await axios.post(api + "_send_by_email");

      let message = response.data.message;
      let status = response.data.status;
     
      dispatch("notification/store", { status, message }, { root: true });
    } catch (error) {
      console.error(error);
      dispatch(
        "notification/storeDownloading",
        {
          status: "error",
          message:
            "Une erreur est survenue lors de l'envoi de la fiche de stock par email.",
        },
        { root: true }
      );
    }
  },

  // async print({ dispatch }, data) {
  //   try {
  //     dispatch(
  //       "notification/storeDownloading",
  //       {
  //         status: "success",
  //         message:
  //           "La fiche de stock actuelle est en téléchargement, Veuillez patienter...",
  //       },
  //       { root: true }
  //     );

  //     const response = await axios.post(api + "_print_page", data, {
  //       responseType: "blob",
  //     });

  //    const pdfContent = response.data.output;
  //    const fileName = response.data.fileName;

  //       FileDownloadService.downloadFile(pdfContent, fileName);

  //   } catch (error) {
  //     console.error(error);
  //   }
  // },
};

// mutations
const mutations = {
  setStocks(state, stocks) {
    state.all = stocks;
    state.stocks = stocks;
  },
  SET_SROCK_FULLNAMES(state, data) {
    state.stockFullNames = data;
  },
  SET_SROCK_HISTORIES(state, data) {
    state.stockHistories = data;
  },
  setOutStocks(state, out_stocks) {
    state.all = out_stocks;
    state.out_stocks = out_stocks;
  },
  setStock(state, stock) {
    state.stock = stock;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
