import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "services";
// initial state
const state = () => ({
  all: [],
  services: [],
  service: {},
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getService: (state) => {
    return state.service;
  },

  getServices: (state) => {
    return state.services;
  },
};

// actions
const actions = {
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let services = response.data;
      commit("setServices", services);
    });
  },

  async getServicesPrices({ commit }) {
    await axios.get(api + "_prices").then((response) => {
      let services = response.data;
      commit("setServicesPrices", services);
    });
  },
  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let service = response.data;
      commit("setService", service);
    });
  },

  async store({ dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;

      dispatch("notification/store", { status, message }, { root: true });
    });
  },

  async storeAndRouteToPurchase({ dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      let data = response.data.data;
      dispatch("notification/store", { status, message }, { root: true });
      router.push({
        name: "purchases-services-create",
        query: { service_reference: data.reference },
      });
    });
  },

  async storePrice({ commit, dispatch }, data) {
    await axios.post(api + "_prices", data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        commit("setServicesPrices", response.data.data);
      }
    });
  },

  async update({ dispatch }, data) {
    await axios
      .post(api + "/" + data.get("reference"), data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        let message = response.data.message;
        let status = response.data.status;
        dispatch("notification/store", { status, message }, { root: true });

        if (status == "success") {
          router.push({
            name: "services-index",
          });
        }
      });
  },

  async destroy({ commit, dispatch }, reference) {
    await axios.delete(api + "/" + reference).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        let services = response.data.data;
        commit("setServices", services);
      }
    });
  },

  async printAll({ dispatch }) {
    try {
      dispatch(
        "notification/storeDownloading",
        { status: "success", message: "downloading..." },
        { root: true }
      );
      const response = await axios
        .get(api + "_print", {
          responseType: "blob", // Set the response type to 'blob' to handle binary data
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Catalogue.pdf");
          link.click();
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  },
};

// mutations
const mutations = {
  setServices(state, services) {
    state.all = services;
    state.services = services;
  },
  setServicesPrices(state, services) {
    state.all = services;
    state.services = services;
  },
  setService(state, service) {
    state.service = service;
  },
  async search(state, value) {
    value = value.charAt(0).toUpperCase() + value.slice(1);
    console.log(value);
    state.services = state.all.filter(function (service) {
      return (
        service.firstName.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        service.lastName.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        service.phone.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        service.reference.toUpperCase().indexOf(value.toUpperCase()) > -1
      );
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
