import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "appointments";
// initial state
const state = () => ({
  all: [],
  appointments: [],
  appointment: {},
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getAppointment: (state) => {
    return state.appointment;
  },

  getAppointments: (state) => {
    return state.appointments;
  },
};

// actions
const actions = {
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let appointments = response.data;
      commit("SET_APPOINTMENTS", appointments);
    });
  },
  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let appointment = response.data;
      commit("SET_APPOINTMENT", appointment);
    });
  },

  async store({ commit, dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") router.push({ name: "appointments-index" });
    });
  },

  async validate({ commit, dispatch }, data) {
    await axios.post(api + "_validate", data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") router.push({ name: "appointments-index" });
    });
  },

  async update({ dispatch }, data) {
    await axios.put(api + "/" + data.reference, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        router.push({
          name: "appointments-index",
        });
      }
    });
  },

  async destroy({ commit, dispatch }, reference) {
    await axios.delete(api + "/" + reference).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      let appointments = response.data.data;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") commit("SET_APPOINTMENTS", appointments);
    });
  },
};

// mutations
const mutations = {
  SET_APPOINTMENTS(state, appointments) {
    state.all = appointments;
    state.appointments = appointments;
  },
  SET_APPOINTMENT(state, appointment) {
    state.appointment = appointment;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
