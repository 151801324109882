import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "employees";
// initial state
const state = () => ({
  all: [],
  employees: [],
  employee: {},
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getEmployee: (state) => {
    return state.employee;
  },

  getEmployees: (state) => {
    return state.employees;
  },
};

// actions
const actions = {
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let employees = response.data;
      commit("setEmployees", employees);
    });
  },
  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let employee = response.data;
      commit("setEmployee", employee);
    });
  },

  async store({ dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        router.push({
          name: "employees-index",
        });
      }
    });
  },

  async update({ commit }, data) {
    await axios
      .put(api + "/" + data.reference, data.employee)
      .then((response) => {
        let message = response.data.message;
        let status = response.data.status;
        let employee = response.data.data;
        this._vm.$toasted.show(message, {
          type: status,
        });

        if (status == "success") {
          commit("setEmployee", employee);
          router.push({
            name: "profile-employee",
            params: {
              reference: employee.reference,
            },
          });
        }
      });
  },
};

// mutations
const mutations = {
  setEmployees(state, employees) {
    state.all = employees;
    state.employees = employees;
  },
  setEmployee(state, employee) {
    state.employee = employee;
  },
  async search(state, value) {
    value = value.charAt(0).toUpperCase() + value.slice(1);
    console.log(value);
    state.employees = state.all.filter(function (employee) {
      return (
        employee.firstName.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        employee.lastName.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        employee.phone.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        employee.reference.toUpperCase().indexOf(value.toUpperCase()) > -1
      );
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
