import axios from "axios";
import { BotMessage, BotMessageType } from "vue-bot-ui";
import constant from "../../constant";
const api = constant.SupportApi + "chats";
const state = {
  messages: [],
};
 
const getters = {
  getMessages: (state) => state.messages,
};

const actions = {
  fetchMessages({ commit }) {
    axios
      .get(`${api}`)
      .then((response) => {
        const messages = response.data;
        commit("setMessages", messages);
      })
      .catch((error) => {
        console.error("Error fetching messages:", error);
      });
  },

  sendMessage({ dispatch }, data) {
    axios
      .post(`${api}`, data)
      .then((response) => {
        dispatch("fetchMessages", response.data);
      })
      .catch((error) => {
        console.error("Error sending message:", error);
      });
  },
};

const mutations = {
  setMessages(state, messages) {
    state.messages = messages;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
