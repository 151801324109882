import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "users";
import access from "../../data/access/index.json";
const data_access = JSON.parse(JSON.stringify(access));
// initial state
const state = () => ({
  all: [],
  users: [],
  user_roles: [],
  user: {},
  user_role: {},
});

// getters
const getters = {
  getAll: () => {
    console.log(access);
    console.log(typeof access);
    console.log(access.length);

    console.log( data_access);
    console.log(typeof data_access);
    console.log(data_access.length);
    return access;
  },
  getUser: (state) => {
    return state.user;
  },

  getUsers: (state) => {
    return state.users;
  },

  getUserRole: (state) => {
    return state.user_role;
  },

  getUserRoles: (state) => {
    return state.user_roles;
  },
};

// actions
const actions = {
  async getUserRoles({ commit }) {
    await axios.get(api + "_roles").then((response) => {
      let user_roles = response.data;
      commit("setUserRoles", user_roles);
    });
  },

  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let users = response.data;
      commit("setUsers", users);
    });
  },

  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let user = response.data;
      commit("setUser", user);
    });
  },

  async store({ commit }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      let user = response.data.data;
      this._vm.$toasted.show(message, {
        type: status,
      });

      if (status == "success") {
        commit("setUser", user);
        console.log(user);
        router.push({
          name: "profile-user",
          params: {
            reference: user.reference,
          },
        });
      }
    });
  },

  async storeRole({ commit }, data) {
    await axios.post(api + "_roles", data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      let user_role = response.data.data;
      this._vm.$toasted.show(message, {
        type: status,
      });

      if (status == "success") {
        commit("setUserRole", user_role);
        console.log(user_role);
        router.push({
          name: "profile-user-role",
          params: {
            reference: user_role.reference,
          },
        });
      }
    });
  },

  async update({ commit }, data) {
    await axios.put(api + "/" + data.reference, data.user).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      let user = response.data.data;
      this._vm.$toasted.show(message, {
        type: status,
      });

      if (status == "success") {
        commit("setUser", user);
        router.push({
          name: "profile-user",
          params: {
            reference: user.reference,
          },
        });
      }
    });
  },
};

// mutations
const mutations = {
  setUsers(state, users) {
    state.all = users;
    state.users = users;
  },
  setUser(state, user) {
    state.user = user;
  },

  setUserRoles(state, user_roles) {
    state.all = user_roles;
    state.user_roles = user_roles;
  },
  setUserRole(state, user_role) {
    state.user_role = user_role;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
