import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "users";
// initial state
const state = () => ({
  all: [],
  users: [],
  user_roles: [],
  user: {},
  user_role: {},
  isUserOnline: "",
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getUser: (state) => {
    return state.user;
  },

  getUsers: (state) => {
    return state.users;
  },

  getUserRole: (state) => {
    return state.user_role;
  },

  getUserRoles: (state) => {
    return state.user_roles;
  },
  getUserOnline: (state) => {
    return state.isUserOnline;
  },
};

// actions
const actions = {
  async getUserRoles({ commit }) {
    await axios.get(api + "_roles").then((response) => {
      let user_roles = response.data;
      commit("setUserRoles", user_roles);
    });
  },

  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let users = response.data;
      commit("setUsers", users);
    });
  },

  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let user = response.data;
      commit("setUser", user);
    });
  },

  async store({ dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        router.push({
          name: "users-index",
        });
      }
    });
  },

  async storeRole({ dispatch }, data) {
    await axios.post(api + "_roles", data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        router.push({
          name: "users-roles-index",
        });
      }
    });
  },

  async update({ dispatch }, data) {
    await axios.put(api + "/" + data.reference, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        router.push({
          name: "users-index",
        });
      }
    });
  },

  async destroy({ commit, dispatch }, reference) {
    await axios.delete(api + "/" + reference).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        let users = response.data.data;
        commit("setUsers", users);
      }
    });
  },

  async destroyRole({ commit, dispatch }, reference) {
    await axios.delete(api + "_roles/" + reference).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        let user_roles = response.data.data;
        commit("setUserRoles", user_roles);
      }
    });
  },

  async showRole({ commit }, reference) {
    await axios.get(api + "_roles/" + reference).then((response) => {
      let user_role = response.data;
      commit("setUserRole", user_role);
    });
  },

  async updateRole({ dispatch }, data) {
    await axios.put(api + "_roles/" + data.reference, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        router.push({
          name: "users-roles-index",
        });
      }
    });
  },
  async fetchUserStatus({ dispatch, commit }) {
    axios
      .get(api + "_status")
      .then((response) => {
        commit("setUserOnline", response.data.isOnline);
        // this.isUserOnline = response.data.isOnline;
      })
      .catch((error) => {
        // Handle the error if needed
      });
  },
};

// mutations
const mutations = {
  setUsers(state, users) {
    state.all = users;
    state.users = users;
  },
  setUser(state, user) {
    state.user = user;
  },

  setUserRoles(state, user_roles) {
    state.all = user_roles;
    state.user_roles = user_roles;
  },
  setUserRole(state, user_role) {
    state.user_role = user_role;
  },
  setUserOnline(state, value) {
    state.isUserOnline = value;
  },
  async search(state, value) {
    value = value.charAt(0).toUpperCase() + value.slice(1);
    console.log(value);
    state.users = state.all.filter(function (user) {
      return (
        user.firstName.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        user.lastName.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        user.phone.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        user.reference.toUpperCase().indexOf(value.toUpperCase()) > -1
      );
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
