const constant = {
  // api: "http://localhost:8400/api/",
  // url: "http://localhost:8400/",

  // SupportApi: "http://localhost:8000/api/",
  // SupportUrl: "http://localhost:8000/",

   SupportApi: "https://api.peascom.ma/api/",
   SupportUrl: "https://api.peascom.ma/",

   api: "https://psm-api.peascom.ma/api/",
   url: "https://psm-api.peascom.ma/",

  // api: "http://192.168.1.31:8400/api/",
  // url: "http://192.168.1.31:8400/",

  // api: "http://192.168.1.250:8400/api/",
  // url: "http://192.168.1.250:8400/",

  // api: "https://depot-api.goodgroupe.com/api/",
  // url: "https://depot-api.goodgroupe.com/",

  // api: "https://api.stock.peascom.store/api/",
  // url: "https://api.stock.peascom.store/",

  // api: "https://api.peascom.store/api/",
  // url: "https://api.peascom.store/",

  //  api: "https://api.oujdafood.ma/api/",
  //  url: "https://api.oujdafood.ma/",

  // api: "https://api.badr.goodgroupe.com/api/",
  // url: "https://api.badr.goodgroupe.com/",

  // api: "https://api.app.goodgroupe.com/api/",
  // url: "https://api.app.goodgroupe.com/",

  // api: "https://api.oujdafood.com/api/",
  // url: "https://api.oujdafood.com/",

  //api: "https://api.psm.peascom.store/api/",
  //url: "https://api.psm.peascom.store/",
};
export default constant;
