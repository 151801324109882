import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "vehicles";
// initial state
const state = () => ({
  all: [],
  vehicles: [],
  vehicle: {},
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getVehicle: (state) => {
    return state.vehicle;
  },

  getVehicles: (state) => {
    return state.vehicles;
  },
};

// actions
const actions = {
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let vehicles = response.data;
      commit("setVehicles", vehicles);
    });
  },

  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let vehicle = response.data;
      commit("setVehicle", vehicle);
    });
  },

  async store({ commit, dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;

      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        router.push({
          name: "vehicles-index",
        });
      }
    });
  },

  async update({ commit }, data) {
    await axios
      .put(api + "/" + data.reference, data.vehicle)
      .then((response) => {
        let message = response.data.message;
        let status = response.data.status;
        let vehicle = response.data.data;
        this._vm.$toasted.show(message, {
          type: status,
        });

        if (status == "success") {
          commit("setVehicle", vehicle);
          router.push({
            name: "profile-vehicle",
            params: {
              reference: vehicle.reference,
            },
          });
        }
      });
  },
};

// mutations
const mutations = {
  setVehicles(state, vehicles) {
    state.all = vehicles;
    state.vehicles = vehicles;
  },

  setVehicle(state, vehicle) {
    state.vehicle = vehicle;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
