import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api ;
// initial state
const state = () => ({
  report: [],
  reportPyments: [],
  statisticsReport: [],
  creditBalances: [],
  discounts: [],
});

// getters
const getters = {
  getReport: (state) => state.report,
  getReportPayments: (state) => state.reportPyments,
  getReportCreditBalances: (state) => state.creditBalances,
  getReportDiscounts: (state) => state.discounts,
  getTotalReportPayments: (state) =>
    state.reportPyments.reduce((acc, val) => acc + parseFloat(val.amount), 0),

  // Customer Report
  getTotalQuantity: (state) =>
    state.report.reduce((acc, val) => acc + parseFloat(val.totalQuantity), 0),
  getTotalPriceTTC: (state) =>
    state.report.reduce((acc, val) => acc + parseFloat(val.totalPriceTTC), 0),
  getTotalPaid: (state) =>
    state.report.reduce((acc, val) => acc + parseFloat(val.amountPaid), 0),
  getTotalUnpaid: (state) =>
    state.report.reduce((acc, val) => acc + parseFloat(val.amountUnpaid), 0),
  getTotalDiscount: (state) =>
    state.report.reduce((acc, val) => acc + parseFloat(val.discount), 0),
};

// actions
const actions = {
  async fetchCustomerReport({ commit }, data) {
    await axios
      .post(api + "archived_customer_report_filter", data)
      .then((response) => {
        let report = response.data.sales;
        let payments = response.data.payments;
        let discounts = response.data.discounts;
        let creditBalances = response.data.creditBalances;
        commit("SET_REPORT", report);
        commit("SET_REPORT_PAYMENTS", payments);
        commit("SET_REPORT_DISCOUNTS", discounts);
        commit("SET_REPORT_CREDIT_BALANCES", creditBalances);
      });
  },

  async storeCustomerReport({ commit }, data) {
    await axios
      .post(api + "archived_customer_report", data)
      .then((response) => {
        let report = response.data.sales;
        let payments = response.data.payments;
        let discounts = response.data.discounts;
        let creditBalances = response.data.creditBalances;
        commit("SET_REPORT", report);
        commit("SET_REPORT_PAYMENTS", payments);
        commit("SET_REPORT_DISCOUNTS", discounts);
        commit("SET_REPORT_CREDIT_BALANCES", creditBalances);
      });
  },
  async destroyCustomerReport({ commit }, data) {
    await axios
      .post(api + "unarchived_customer_report", data)
      .then((response) => {
        let report = response.data.sales;
        let payments = response.data.payments;
        let discounts = response.data.discounts;
        let creditBalances = response.data.creditBalances;
        commit("SET_REPORT", report);
        commit("SET_REPORT_PAYMENTS", payments);
        commit("SET_REPORT_DISCOUNTS", discounts);
        commit("SET_REPORT_CREDIT_BALANCES", creditBalances);
      });
  },
};

// mutations
const mutations = {
  SET_REPORT(state, report) {
    state.report = report;
  },
  SET_REPORT_PAYMENTS(state, data) {
    state.reportPyments = data;
  },
  SET_REPORT_CREDIT_BALANCES(state, data) {
    state.creditBalances = data;
  },
  SET_REPORT_DISCOUNTS(state, data) {
    state.discounts = data;
  },
  SET_STATISTICS_REPORT(state, data) {
    state.statisticsReport = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
