<template>
  <div id="app" dir="ltr">
    <vue-confirm-dialog></vue-confirm-dialog>
    <notifications group="foo" />

    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>
<script>
export default {
  computed: {
    layout() {
      // return ("layout-" + this.$route.meta.layout).toLowerCase();
      return "layout-" + (this.$route.meta.layout || "default").toLowerCase();
    },
    
  },
};
</script>
<style></style>
