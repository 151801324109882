<template>
  <div id="app" v-if="!onLine" class="rounded">
    <div class="text-danger">
      <div class=" ">
        <span class="" >
          <i class="bi bi-wifi-off " :style="{ fontSize: '4em !important' }" ></i>
        </span>
      </div>
      <br />
      <span> Aucune connexion Internet, merci de réessayer. </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      onLine: navigator.onLine,
      showBackOnline: false,
    };
  },
  methods: {
    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === "online";
    },
  },
  watch: {
    onLine(v) {
      if (v) {
        this.showBackOnline = true;
        setTimeout(() => {
          this.showBackOnline = false;
        }, 1000);
      }
    },
  },
  mounted() {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },
  beforeDestroy() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
  position: absolute;
  z-index: 1245603;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* opacity: .2; */
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
#app > div {
  text-align: center;
  background-color: #fff;
  left: auto;
  right: auto;
  width: 600px;
  height: 180px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
}
</style>
