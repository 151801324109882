import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "repairs";
// initial state
const state = () => ({
  all: [],
  repairs: [],
  items: [],
  serices: [],
  repair: {},
  report: [],
  reportPyments: [],
  currentPage: 1,
  perPage: 10,
  totalItems: 0,
  errors: [],
  statisticsReport: [],

  creditBalances: [],
  discounts: [],
});

// getters
const getters = {
  getAll: (state) => state.all,
  getErrors: (state) => state.errors,
  getRepairs: (state) => state.repairs,
  getStatisticsReport: (state) => state.statisticsReport,
  getCurrentPage: (state) => state.currentPage,
  getPerPage: (state) => state.perPage,
  getTotalItems: (state) => state.totalItems,

  getRepair: (state) => state.repair,
  getItems: (state) => state.items,
  getServices: (state) => state.serices,

  getReport: (state) => state.report,
  getReportPayments: (state) => state.reportPyments,
  getReportCreditBalances: (state) => state.creditBalances,
  getReportDiscounts: (state) => state.discounts,
  getTotalReportPayments: (state) =>
    state.reportPyments.reduce((acc, val) => acc + parseFloat(val.amount), 0),

  getLineChartDataReport: (state) => {
    const cumulativeTotalPriceTTC = state.report.reduce((acc, curr) => {
      const previousSum = acc.length > 0 ? acc[acc.length - 1] : 0;
      const currentSum = previousSum + curr.totalPriceTTC;
      acc.push(currentSum);
      return acc;
    }, []);

    const cumulativeAmountPaid = state.report.reduce((acc, curr) => {
      const previousSum = acc.length > 0 ? acc[acc.length - 1] : 0;
      const currentSum = previousSum + curr.amountPaid;
      acc.push(currentSum);
      return acc;
    }, []);

    const cumulativeAmountUnpaid = state.report.reduce((acc, curr) => {
      const previousSum = acc.length > 0 ? acc[acc.length - 1] : 0;
      const currentSum = previousSum + curr.amountUnpaid;
      acc.push(currentSum);
      return acc;
    }, []);

    return {
      labels: state.report != "" ? state.report.map(({ date }) => date) : [""],
      datasets: [
        {
          label: "Montant Total",
          data: cumulativeTotalPriceTTC,
          borderColor: "#337dff",
          borderWidth: 1,
        },
        {
          label: "Montant Payé",
          data: cumulativeAmountPaid,
          borderColor: "#33ff71",
          borderWidth: 1,
        },
        {
          label: "Montant Restant",
          data: cumulativeAmountUnpaid,
          borderColor: "#ff3333",
          borderWidth: 1,
        },
      ],
    };
  },

  totalPriceTTCItems: (state) =>
    state.items.reduce(
      (acc, val) => acc + parseFloat(val.quantity) * parseFloat(val.priceTTC),
      0
    ),

  totalQuantityItems: (state) =>
    state.items.reduce((acc, val) => acc + parseFloat(val.quantity), 0),
  numberItems: (state) => parseFloat(state.items.length),
  // Customer Report
  getTotalQuantity: (state) =>
    state.report.reduce((acc, val) => acc + parseFloat(val.totalQuantity), 0),
  getTotalPriceTTC: (state) =>
    state.report.reduce((acc, val) => acc + parseFloat(val.totalPriceTTC), 0),
  getTotalPaid: (state) =>
    state.report.reduce((acc, val) => acc + parseFloat(val.amountPaid), 0),
  getTotalUnpaid: (state) =>
    state.report.reduce((acc, val) => acc + parseFloat(val.amountUnpaid), 0),
  getTotalDiscount: (state) =>
    state.report.reduce((acc, val) => acc + parseFloat(val.discount), 0),

  // Repairs
  getRepairsTotalQuantity: (state) =>
    state.repairs.reduce((acc, val) => acc + parseFloat(val.totalQuantity), 0),
  getRepairsTotalPriceTTC: (state) =>
    state.repairs.reduce((acc, val) => acc + parseFloat(val.totalPriceTTC), 0),
  getRepairsTotalPaid: (state) =>
    state.repairs.reduce((acc, val) => acc + parseFloat(val.amountPaid), 0),
  getRepairsTotalUnpaid: (state) =>
    state.repairs.reduce((acc, val) => acc + parseFloat(val.amountUnpaid), 0),
  getRepairsTotalDiscount: (state) =>
    state.repairs.reduce((acc, val) => acc + parseFloat(val.discount), 0),
};

// actions
const actions = {
  async fetchRepairs({ commit, dispatch, state }) {
    try {
      const response = await axios.get(api);
      const { data } = response;

      if (response.data == -1) {
        dispatch(
          "notification/store",
          {
            status: "warning",
            message: "Pas de données, la liste des ventes est vide.",
          },
          { root: true }
        );
      }
      commit("SET_REPAIRS", data);
      // commit("SET_TOTAL_ITEMS", data.totalItems);
    } catch (error) {
      console.error(error);
    }
  },

  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let repairs = response.data;
      commit("SET_REPAIRS", repairs);
    });
  },

  async fetchAllStatistics({ commit }, data) {
    await axios.post(api + "_statistics_report", data).then((response) => {
      const { data } = response;
      commit("SET_STATISTICS_REPORT", data);
    });
  },

  async getTodays({ commit }) {
    await axios.get(api + "_todays").then((response) => {
      let repairs = response.data;
      commit("SET_REPAIRS", repairs);
    });
  },

  async getAllItems({ commit }) {
    await axios.get(api + "_items").then((response) => {
      commit("setItems", response.data);
    });
  },

  async getAllServices({ commit }) {
    await axios.get(api + "_services").then((response) => {
      commit("SET_SERVICES", response.data);
    });
  },
  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let repair = response.data;
      commit("setRepair", repair);
    });
  },

  async reportCustomer({ commit }, data) {
    await axios.post(api + "_customer_report", data).then((response) => {
      let report = response.data.repairs;
      let payments = response.data.payments;
      let discounts = response.data.discounts;
      let creditBalances = response.data.creditBalances;
      commit("setReport", report);
      commit("SET_REPORT_PAYMENTS", payments);
      commit("SET_REPORT_DISCOUNTS", discounts);
      commit("SET_REPORT_CREDIT_BALANCES", creditBalances);
    });
  },

  async getCustomerRepairsUnpaid({ commit }, customer_reference) {
    await axios.get(api + "_unpaid/" + customer_reference).then((response) => {
      let repairs = response.data;
      commit("SET_REPAIRS", repairs);
    });
  },
  async recalculationPrices({ commit, dispatch }, reference) {
    await axios
      .get(api + "_recalculation_prices/" + reference)
      .then((response) => {
        let repair = response.data.data;
        let message = response.data.message;
        let status = response.data.status;
        dispatch("notification/store", { status, message }, { root: true });
        commit("setRepair", repair);
      });
  },

  async store({ commit, dispatch }, data) {
    try {
      await axios.post(api, data).then((response) => {
        let message = response.data.message;
        let status = response.data.status;

        dispatch("notification/store", { status, message }, { root: true });
        if (status == "success") {
          router.push({
            name: "repairs-index",
          });
        }
      });
    } catch (error) {
      if (error.response) {
        const statusCode = error.response.status;
        let errorMessage = "Unprocessable Content";
        let errorMessages = [];

        if (error.response.data.errors) {
          // Construct an array of error messages from the errors object
          const errors = error.response.data.errors;
          for (const field in errors) {
            errorMessages.push(...errors[field]);
          }
          commit("SET_ERRORS", errorMessages);
          setTimeout(function () {
            commit("SET_ERRORS", []);
          }, 10000);
        }
        if (error.response.data.message) {
          errorMessage = error.response.data.message;
        }

        console.error("API Error:", errorMessage);

        // Dispatch the error notification along with status code and error messages array
        dispatch(
          "notification/store",
          { status: "error", message: errorMessage, statusCode, errorMessages },
          { root: true }
        );
      } else {
        console.error("An error occurred:", error.message);
      }
    }
  },

  async storeItem({ commit, dispatch }, data) {
    try {
      await axios.post(api + "_items", data).then((response) => {
        let message = response.data.message;
        let status = response.data.status;

        dispatch("notification/store", { status, message }, { root: true });
        if (status == "success") {
          commit("setItems", response.data.data);
        }
      });
    } catch (error) {
      if (error.response) {
        const statusCode = error.response.status;
        let errorMessage = "Unprocessable Content";
        let errorMessages = [];

        if (error.response.data.errors) {
          // Construct an array of error messages from the errors object
          const errors = error.response.data.errors;
          for (const field in errors) {
            errorMessages.push(...errors[field]);
          }
          commit("SET_ERRORS", errorMessages);
          setTimeout(function () {
            commit("SET_ERRORS", []);
          }, 10000);
        }
        if (error.response.data.message) {
          errorMessage = error.response.data.message;
        }

        console.error("API Error:", errorMessage);

        // Dispatch the error notification along with status code and error messages array
        dispatch(
          "notification/store",
          { status: "error", message: errorMessage, statusCode, errorMessages },
          { root: true }
        );
      } else {
        console.error("An error occurred:", error.message);
      }
    }
  },

  async storeService({ commit, dispatch }, data) {
    try {
      await axios.post(api + "_services", data).then((response) => {
        let message = response.data.message;
        let status = response.data.status;

        dispatch("notification/store", { status, message }, { root: true });
        if (status == "success") {
          commit("SET_SERVICES", response.data.data);
        }
      });
    } catch (error) {
      if (error.response) {
        dispatch(
          "notification/store",
          { status: "error", message: errorMessage, statusCode, errorMessages },
          { root: true }
        );
      } else {
        console.error("An error occurred:", error.message);
      }
    }
  },

  async storePhotos({ commit, dispatch }, data) {
    try {
      await axios.post(api + "_photos", data).then((response) => {
        let message = response.data.message;
        let status = response.data.status;

        dispatch("notification/store", { status, message }, { root: true });
        if (status == "success") {
          commit("setItems", response.data.data);
        }
      });
    } catch (error) {
      if (error.response) {
        dispatch(
          "notification/store",
          { status: "error", message: errorMessage, statusCode, errorMessages },
          { root: true }
        );
      } else {
        console.error("An error occurred:", error.message);
      }
    }
  },

  async updateItem({ commit, dispatch }, data) {
    try {
      await axios.put(api + "_items/" + data.id, data).then((response) => {
        let message = response.data.message;
        let status = response.data.status;

        dispatch("notification/store", { status, message }, { root: true });
        if (status == "success") {
          commit("setItems", response.data.data);
        }
      });
    } catch (error) {
      if (error.response) {
        const statusCode = error.response.status;
        let errorMessage = "Unprocessable Content";
        let errorMessages = [];

        if (error.response.data.errors) {
          // Construct an array of error messages from the errors object
          const errors = error.response.data.errors;
          for (const field in errors) {
            errorMessages.push(...errors[field]);
          }
          commit("SET_ERRORS", errorMessages);
          setTimeout(function () {
            commit("SET_ERRORS", []);
          }, 10000);
        }
        if (error.response.data.message) {
          errorMessage = error.response.data.message;
        }

        console.error("API Error:", errorMessage);

        // Dispatch the error notification along with status code and error messages array
        dispatch(
          "notification/store",
          { status: "error", message: errorMessage, statusCode, errorMessages },
          { root: true }
        );
      } else {
        console.error("An error occurred:", error.message);
      }
    }
  },

  async update({ commit, dispatch }, data) {
    await axios.put(api + "/" + data.reference, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      let repair = response.data.data;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        router.push({
          name: "repairs-details",
          params: {
            reference: repair.reference,
          },
        });
      }
    });
  },

  async validate({ commit, dispatch }, data) {
    await axios
      .post(api + "_validate", { reference: data })
      .then((response) => {
        let message = response.data.message;
        let status = response.data.status;
        let repair = response.data.data;
        dispatch("notification/store", { status, message }, { root: true });
        if (status == "success") {
          router.push({
            name: "repairs-index",
          });
        }
      });
  },

  async destroyItem({ commit, dispatch }, id) {
    await axios.delete(api + "_items/" + id).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      let repair = response.data.data;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        commit("setItems", response.data.data);
      }
    });
  },

  async destroy({ dispatch }, reference) {
    await axios.delete(api + "/" + reference).then((response) => {
      let message = response.data.message;
      let status = response.data.status;

      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        router.push({ name: "repairs-index" });
      }
    });
  },

  async print({ dispatch }, reference) {
    try {
      dispatch(
        "notification/storeDownloading",
        { status: "success", message: "downloading..." },
        { root: true }
      );
      const response = await axios
        .get(api + "_print_page/" + reference, {
          responseType: "blob", // Set the response type to 'blob' to handle binary data
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Facture.pdf");
          link.click();
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  },

  async printManyBill({ dispatch }, data) {
    try {
      dispatch(
        "notification/storeDownloading",
        {
          status: "success",
          message:
            "Les factures sélectionnées sont en cours de téléchargement, veuillez patienter....",
        },
        { root: true }
      );
      const response = await axios
        .post(
          api + "_print_many_bill",
          { selections: data },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Factures.pdf");
          link.click();
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  },

  async printRepairOrder({ dispatch }, reference) {
    try {
      dispatch(
        "notification/storeDownloading",
        { status: "success", message: "downloading..." },
        { root: true }
      );
      const response = await axios
        .get(api + "_print_repair_order/" + reference, {
          responseType: "blob", // Set the response type to 'blob' to handle binary data
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Devis.pdf");
          link.click();
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  },

  async printReportCustomer({ dispatch }, data) {
    try {
      dispatch(
        "notification/storeDownloading",
        { status: "success", message: "downloading..." },
        { root: true }
      );
      const response = await axios
        .post(api + "_customer_report_print", data, {
          responseType: "blob", // Set the response type to 'blob' to handle binary data
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Rapport Client.pdf");
          link.click();
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }

    // await axios.post(api + "_customer_report_print", data).then((response) => {
    //   let report = response.data;
    //   commit("setReport", report);
    // });
  },

  async printGeneralReportCustomer({ dispatch }, data) {
    try {
      dispatch(
        "notification/storeDownloading",
        { status: "success", message: "downloading..." },
        { root: true }
      );
      const response = await axios
        .post(api + "_customer_general_report_print", data, {
          responseType: "blob", // Set the response type to 'blob' to handle binary data
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Rapport Generale Client.pdf");
          link.click();
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }

    // await axios.post(api + "_customer_report_print", data).then((response) => {
    //   let report = response.data;
    //   commit("setReport", report);
    // });
  },

  async filter({ dispatch, commit }, data) {
    await axios.post(api + "_filter", data).then((response) => {
      commit("SET_REPAIRS", response.data);
    });
  },

  async uploadOrderItems({ commit, dispatch }, reference) {
    await axios
      .get(api + "_upload_order_items/" + reference)
      .then((response) => {
        let message = response.data.message;
        let status = response.data.status;

        dispatch("notification/store", { status, message }, { root: true });
        if (status == "success") {
          commit("setItems", response.data.data);
        }
      });
  },

  async archiveReportCustomer({ commit }, data) {
    await axios
      .post(api + "_customer_report_archive", data)
      .then((response) => {
        let report = response.data.repairs;
        let payments = response.data.payments;
        let discounts = response.data.discounts;
        let creditBalances = response.data.creditBalances;
        commit("setReport", report);
        commit("SET_REPORT_PAYMENTS", payments);
        commit("SET_REPORT_DISCOUNTS", discounts);
        commit("SET_REPORT_CREDIT_BALANCES", creditBalances);
      });
  },
};

// mutations
const mutations = {
  SET_ERRORS(state, errors) {
    state.errors = errors;
  },
  SET_REPAIRS(state, repairs) {
    state.all = repairs;
    state.repairs = repairs;
  },
  SET_TOTAL_ITEMS(state, total) {
    state.totalItems = total;
  },
  SET_CURRENT_PAGE(state, page) {
    state.currentPage = page;
  },
  setRepair(state, repair) {
    state.repair = repair;
  },
  setReport(state, report) {
    state.report = report;
  },
  SET_REPORT_PAYMENTS(state, data) {
    state.reportPyments = data;
  },
  SET_REPORT_CREDIT_BALANCES(state, data) {
    state.creditBalances = data;
  },
  SET_REPORT_DISCOUNTS(state, data) {
    state.discounts = data;
  },
  SET_STATISTICS_REPORT(state, data) {
    state.statisticsReport = data;
  },
  setItems(state, items) {
    state.items = items;
  },

  SET_SERVICES(state, items) {
    state.serices = items;
  },
  async search(state, value) {
    value = value.charAt(0).toUpperCase() + value.slice(1);
    console.log(value);
    state.repairs = state.all.filter(function (repair) {
      return (
        repair.firstName.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        repair.lastName.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        repair.phone.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        repair.reference.toUpperCase().indexOf(value.toUpperCase()) > -1
      );
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
