<template>
  <div>
    <VueBotUI
      :messages="messages"
      :options="botOptions"
      @msg-send="messageSendHandler"
      :bot-typing="false"
      :open-delay="1000"
    />
  </div>
</template>
<script>
import { VueBotUI } from "vue-bot-ui";
import VoiceRecognition from "vue-speech-recognition";
import { mapGetters } from "vuex";

export default {
  components: {
    VueBotUI,
    VoiceRecognition,
  },
  data() {
    return {
      botOptions: {
        botTitle: "Peascom Support",
      },
    };
  },
  computed: {
    ...mapGetters("message", {
      messages: "getMessages",
    }),
    ...mapGetters("auth", {
      authData: "getAuthData",
    }),
  },
  methods: {
    messageSendHandler(value) {
      console.log(value);
      value.agent='user'
      this.$store.dispatch("message/sendMessage", value);
    },
  },
  mounted() {
    this.$store.dispatch("message/fetchMessages");
    // Initialize the voice recognition
    this.$root.$on("VoiceRecognition.StartRecording", () => {
      this.handleMessageSend({ text: "" });
    });
    this.$root.$on("VoiceRecognition.Recognized", (text) => {
      this.handleMessageSend({ text });
    });
  },
};
</script>
