import constant from "../../constant";
import axios from "axios";
const api = constant.api + "troubleshootings";
// initial state
const state = () => ({
  issues: [],
  currentIssue: null,
  isLoading: false,
  errorMessage: null,
});

// getters
const getters = {
  issues: (state) => state.issues,
  currentIssue: (state) => state.currentIssue,
  isLoading: (state) => state.isLoading,
  errorMessage: (state) => state.errorMessage,
};

// actions
const actions = {
  async fetchIssues({ commit }) {
    try {
      commit("SET_LOADING", true);
      const response = await axios.get(api);
      commit("SET_ISSUES", response.data);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR_MESSAGE", error.message);
    }
  },
  async createIssue({ commit }, issue) {
    try {
      commit("SET_LOADING", true);
      const response = await axios.post(api, issue);
      commit("SET_CURRENT_ISSUE", response.data);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR_MESSAGE", error.message);
    }
  },
  async updateIssue({ commit }, issue) {
    try {
      commit("SET_LOADING", true);
      const response = await axios.put(`/api/issues/${issue.id}`, issue);
      commit("SET_CURRENT_ISSUE", response.data);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR_MESSAGE", error.message);
    }
  },
  async resolveIssue({ commit }, issue) {
    try {
      commit("SET_LOADING", true);
      const response = await axios.put(
        `/api/issues/${issue.id}/resolve`,
        issue
      );
      commit("SET_CURRENT_ISSUE", response.data);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR_MESSAGE", error.message);
    }
  },
};

// mutations
const mutations = {
  SET_ISSUES(state, issues) {
    state.issues = issues;
  },
  SET_CURRENT_ISSUE(state, issue) {
    state.currentIssue = issue;
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_ERROR_MESSAGE(state, errorMessage) {
    state.errorMessage = errorMessage;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
