import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "expenses";
// initial state
const state = () => ({
  all: [],
  expenses: [],
  expense: {},
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getExpense: (state) => {
    return state.expense;
  },

  getExpenses: (state) => {
    return state.expenses;
  },
};

// actions
const actions = {
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let expenses = response.data;
      commit("setExpenses", expenses);
    });
  },

  async getExpensesPrices({ commit }) {
    await axios.get(api + "_prices").then((response) => {
      let expenses = response.data;
      commit("setExpensesPrices", expenses);
    });
  },
  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let expense = response.data;
      commit("setExpense", expense);
    });
  },

  async store({  dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        router.push({
          name: "expenses-index",
        });
      }
    });
  },

  async storePrice({ commit, dispatch }, data) {
    await axios.post(api + "_prices", data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
    });
  },

  async update({ commit }, data) {
    await axios
      .put(api + "/" + data.reference, data.expense)
      .then((response) => {
        let message = response.data.message;
        let status = response.data.status;
        let expense = response.data.data;
        this._vm.$toasted.show(message, {
          type: status,
        });

        if (status == "success") {
          commit("setExpense", expense);
          router.push({
            name: "profile-expense",
            params: {
              reference: expense.reference,
            },
          });
        }
      });
  },
};

// mutations
const mutations = {
  setExpenses(state, expenses) {
    state.all = expenses;
    state.expenses = expenses;
  },
  setExpensesPrices(state, expenses) {
    state.all = expenses;
    state.expenses = expenses;
  },
  setExpense(state, expense) {
    state.expense = expense;
  },
  async search(state, value) {
    value = value.charAt(0).toUpperCase() + value.slice(1);
    console.log(value);
    state.expenses = state.all.filter(function (expense) {
      return (
        expense.firstName.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        expense.lastName.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        expense.phone.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        expense.reference.toUpperCase().indexOf(value.toUpperCase()) > -1
      );
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
