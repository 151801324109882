import constant from "../../constant";
import axios from "axios";

const api = constant.api + "statistics";
// initial state
const state = () => ({
  all: [],
  totalTodays: [],
  quantityAnalytics: [],
});

// getters
const getters = {
  getAll: (state) => state.all,

  getTotalTodays: (state) => state.totalTodays,
  getBarTodaysChartData: (state) => {
    return {
      labels: ["Total Ventes", "Payé", "Impayé", "Règlement "],
      datasets: [
        {
          label: "Ventes",
          data: state.totalTodays ? Object.values(state.totalTodays.sales) : [],
          backgroundColor: ["#0dcaf0", "#198754", "#dc3545", "#fd7e14"],
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      ],
    };
  },

  getQuantityAnalytics: (state) => state.quantityAnalytics,
};

// actions
const actions = {
  async downloadDailySheet({ commit }, data) {
    try {
      const response = await axios.post(
        api + "/print/daily/sheet",
        { date: data.date, selections: data.selections },
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Fiche Journalière " + data.date + ".pdf");
      link.click();
    } catch (error) {
      console.error(error);
    }
  },
  async fetchTotalTodays({ commit }) {
    try {
      axios.get(api + "/total/todays").then((response) => {
        commit("setTotalTodays", response.data);
      });
    } catch (error) {
      console.error(error);
    }
  },

  async fetchQuantityAnalytics({ commit }, date) {
    try {
      axios.get(api + "/quantity/analytics/" + date).then((response) => {
        commit("SET_QUANTITY_ANALYTICS", response.data);
      });
    } catch (error) {
      console.error(error);
    }
  },
};

// mutations
const mutations = {
  setTotalTodays(state, data) {
    state.totalTodays = data;
  },
  SET_QUANTITY_ANALYTICS(state, data) {
    state.quantityAnalytics = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
