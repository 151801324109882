import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "sections";
// initial state
const state = () => ({
  all: [],
  sections: [],
  section: {},
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getSection: (state) => {
    return state.section;
  },

  getSections: (state) => {
    return state.sections;
  },
};

// actions
const actions = {
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let sections = response.data;
      commit("setSections", sections);
    });
  },
  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let section = response.data;
      commit("setSection", section);
    });
  },

  async store({ commit, dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") router.push({ name: "sections-index" });
    });
  },
 
  async update({ dispatch }, data) {
    await axios.put(api + "/" + data.reference, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        router.push({
          name: "sections-index",
        });
      }
    });
  },

  async destroy({ commit, dispatch }, reference) {
    await axios.delete(api + "/" + reference).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      let sections = response.data.data;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") commit("setSections", sections);
    });
  },
};

// mutations
const mutations = {
  setSections(state, sections) {
    state.all = sections;
    state.sections = sections;
  },
  setSection(state, section) {
    state.section = section;
  },
  async search(state, value) {
    value = value.charAt(0).toUpperCase() + value.slice(1);
    console.log(value);
    state.sections = state.all.filter(function (section) {
      return (
        section.firstName.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        section.lastName.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        section.phone.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        section.reference.toUpperCase().indexOf(value.toUpperCase()) > -1
      );
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
