var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.alert.message != '')?_c('div',{staticClass:"_alert rounded",class:_vm.alert.status == 'success'
      ? 'bg-success text-white'
      : _vm.alert.status == 'error'
      ? 'bg-danger text-white'
      : _vm.alert.status == 'warning'
      ? 'bg-warning text-white'
      : _vm.alert.status == 'info'
      ? 'bg-info text-white'
      : _vm.alert.status == 'primary'
      ? 'bg-primary text-white'
      : ''},[_c('div',{staticClass:"p-1 row align-items-center"},[(_vm.alert.status)?_c('div',{staticClass:"col-auto"},[(_vm.alert.status == 'success')?_c('i',{staticClass:"bi bi-check2-all text-white fs-5"}):_vm._e(),(_vm.alert.status == 'error')?_c('i',{staticClass:"bi bi-check2-all text-white fs-5"}):_vm._e()]):_vm._e(),_c('div',{staticClass:"col p-0",domProps:{"innerHTML":_vm._s(_vm.alert.message)}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }