import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "quotations";
// initial state
const state = () => ({
  all: [],
  quotations: [],
  items: [],
  quotationSupplierItems: [],
  quotation: {},
  supplier_quotations: [],
  supplierQuotation: {},
});

// getters
const getters = {
  getAll: (state) => state.all,
  getQuotationSuppliers: (state) => state.supplier_quotations,
  getItems: (state) => state.items,

  getQuotationSupplierItems: (state) => state.quotationSupplierItems,
  getQuotation: (state) => state.quotation,
  getSupplierQuotation: (state) => state.supplierQuotation,

  getQuotations: (state) => {
    return state.quotations;
  },

  getSupplierQuotations: (state) => {
    return state.supplier_quotations;
  },
  totalPriceTTCItems: (state) => {
    if (Array.isArray(state.items)) {
      // reduce here
      return state.items.reduce(
        (acc, val) => acc + val.quantity * val.priceTTC,
        0
      );
    }
  },

  totalQuantityItems: (state) => {
    if (Array.isArray(state.items)) {
      // reduce here
      return state.items.reduce((acc, val) => acc + val.quantity, 0);
    }
  },
  numberItems: (state) => {
    if (Array.isArray(state.items)) {
      // reduce here
      return state.items.length;
    }
  },
};

// actions
const actions = {
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let quotations = response.data;
      commit("setQuotations", quotations);
    });
  },

  async getSupplierQuotations({ commit }) {
    await axios.get(api + "_suppliers").then((response) => {
      let quotations = response.data;
      commit("setSupplierQuotations", quotations);
    });
  },

  async getAllOujdaFood({ commit }) {
    await axios.get(api + "_oujdafood").then((response) => {
      let quotations = response.data;
      commit("setQuotations", quotations);
    });
  },

  async getAllItems({ commit }) {
    await axios.get(api + "_items").then((response) => {
      commit("setItems", response.data);
    });
  },
  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let quotation = response.data;
      commit("setQuotation", quotation);
    });
  },

  async showSupplierQuotation({ commit }, reference) {
    await axios.get(api + "_suppliers/" + reference).then((response) => {
      let quotation = response.data;
      commit("SET_SUPPLIER_QUotation", quotation);
    });
  },

  async print({ dispatch }, reference) {
    try {
      dispatch(
        "notification/storeDownloading",
        { status: "success", message: "downloading..." },
        { root: true }
      );
      const response = await axios
        .get(api + "_print/" + reference, {
          responseType: "blob", // Set the response type to 'blob' to handle binary data
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Devis.pdf");
          link.click();
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  },

  async showOujdaFood({ commit }, reference) {
    await axios.get(api + "_oujdafood/" + reference).then((response) => {
      let quotation = response.data;
      commit("setQuotation", quotation);
    });
  },
  async getCustomerQuotationsUnpaid({ commit }, customer_reference) {
    await axios.get(api + "_unpaid/" + customer_reference).then((response) => {
      let quotations = response.data;
      commit("setQuotations", quotations);
    });
  },

  async storeItem({ commit, dispatch }, data) {
    await axios.post(api + "_items", data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;

      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        commit("setItems", response.data.data);
      }
    });
  },

  async store({ commit, dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        router.push({ name: "quotations-index" });
      }
    });
  },

  async storeSupplierQuotation({ commit, dispatch }, data) {
    await axios.post(api + "_suppliers", data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        router.push({ name: "quotations-suppliers-index" });
      }
    });
  },

  async update({ commit }, data) {
    await axios
      .put(api + "/" + data.reference, data.quotation)
      .then((response) => {
        let message = response.data.message;
        let status = response.data.status;
        let quotation = response.data.data;
        this._vm.$toasted.show(message, {
          type: status,
        });

        if (status == "success") {
          commit("setQuotation", quotation);
          router.push({
            name: "profile-quotation",
            params: {
              reference: quotation.reference,
            },
          });
        }
      });
  },

  async getQuotationSupplierItem({ commit, dispatch }) {
    await axios.get(api + "_suppliers_items").then((response) => {
      commit("SET_QUOTATION_SUPPLIER_ITEMS", response.data);
    });
  },
  async storeQuotationSupplierItem({ commit, dispatch }, data) {
    await axios.post(api + "_suppliers_items", data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;

      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        commit("SET_QUOTATION_SUPPLIER_ITEMS", response.data.data);
      }
    });
  },
  async destroyQuotationSupplierItem({ commit, dispatch }, id) {
    await axios.delete(api + "_suppliers_items/" + id).then((response) => {
      let message = response.data.message;
      let status = response.data.status;

      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        commit("SET_QUOTATION_SUPPLIER_ITEMS", response.data.data);
      }
    });
  },
};

// mutations
const mutations = {
  setQuotations(state, quotations) {
    state.all = quotations;
    state.quotations = quotations;
  },
  setSupplierQuotations(state, quotations) {
    state.supplier_quotations = quotations;
  },

  setItems(state, items) {
    state.items = items;
  },
  SET_QUOTATION_SUPPLIER_ITEMS(state, items) {
    state.quotationSupplierItems = items;
  },
  setQuotation(state, quotation) {
    state.quotation = quotation;
  },
  SET_SUPPLIER_QUOTATION(state, supplier_quotation) {
    state.supplierQuotation = supplier_quotation;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
