<template>
  <div>
    <button
      class="menu-button"
      @click="toggleSidebar"
      :class="{ open: isSidebarOpen }"
    >
      <i class="bi bi-list text-white" v-if="!isSidebarOpen"></i>
      <i class="bi bi-x-lg text-white" v-else></i>
    </button>
    <div class="s-layout__sidebar" :class="{ hide: !isSidebarOpen }">
      <div class="s-sidebar__trigger">
        <span>
          <i
            class="bi bi-logo bi-kanban-fill"
            :style="{
              fontSize: '3rem !important',
            }"
          ></i>
        </span>
        <!-- <div>PSM</div> -->
      </div>

      <nav class="s-sidebar__nav">
        <ul class="">
          <!-- dashboard -->
          <li>
            <router-link
              :to="{ name: 'dashboard' }"
              :class="$route.name.includes('dashboard') ? 'active' : ''"
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-back"></i>
              <div>
                {{ $t("sidebar1") }}
              </div>
            </router-link>
          </li>

          <!-- statistics -->
          <li>
            <router-link
              :to="{ name: 'statistics-index' }"
              :class="$route.name.includes('statistics') ? 'active' : ''"
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-bar-chart-line-fill"></i>
              <div>
                {{ $t("sidebar2") }}
              </div>
            </router-link>
          </li>

          <!-- sales -->
          <li>
            <router-link
              :to="{ name: 'sales-index' }"
              :class="$route.name.includes('sales') ? 'active' : ''"
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-bag-check-fill"></i>
              <div>
                {{ $t("sidebar3") }}
              </div>
            </router-link>
          </li>

          <!-- repairs -->
          <li>
            <router-link
              :to="{ name: 'repairs-index' }"
              :class="$route.name.includes('repairs') ? 'active' : ''"
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-bag-check-fill"></i>
              <div>Réparations</div>
            </router-link>
          </li>

          <!-- archives -->
          <li>
            <router-link
              :to="{ name: 'archives-index' }"
              :class="$route.name.includes('archives') ? 'active' : ''"
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-archive-fill"></i>
              <div>Archives</div>
            </router-link>
          </li>

          <!-- stocks -->
          <li>
            <router-link
              :to="{ name: 'stocks-index' }"
              :class="$route.name.includes('stocks') ? 'active' : ''"
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-database-fill"></i>
              <div>
                {{ $t("sidebar4") }}
              </div>
            </router-link>
          </li>

          <!-- payments -->
          <li>
            <router-link
              :to="{ name: 'payments-customers-index' }"
              :class="$route.name.includes('payments') ? 'active' : ''"
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-currency-bitcoin"></i>
              <div>
                {{ $t("sidebar5") }}
              </div>
            </router-link>
          </li>

          <!-- discounts -->
          <li>
            <router-link
              :to="{ name: 'discounts-index' }"
              :class="$route.name.includes('discounts') ? 'active' : ''"
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-send-dash-fill"></i>
              <div>
                {{ $t("sidebar22") }}
              </div>
            </router-link>
          </li>

          <!-- checks -->
          <li>
            <router-link
              :to="{ name: 'checks-customers-index' }"
              :class="$route.name.includes('checks') ? 'active' : ''"
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-cash"></i>
              <div>
                {{ $t("sidebar6") }}
              </div>
            </router-link>
          </li>

          <!-- credits -->
          <li>
            <router-link
              :to="{ name: 'credits-index' }"
              :class="$route.name.includes('credits') ? 'active' : ''"
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-wallet-fill"></i>
              <div>
                {{ $t("sidebar7") }}
              </div>
            </router-link>
          </li>

          <!-- purchases -->
          <li>
            <router-link
              :to="{ name: 'purchases-index' }"
              :class="$route.name.includes('purchases') ? 'active' : ''"
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-cursor-fill"></i>
              <div>
                {{ $t("sidebar8") }}
              </div>
            </router-link>
          </li>

          <!-- orders -->
          <li>
            <router-link
              :to="{ name: 'orders-index' }"
              :class="$route.name.includes('orders') ? 'active' : ''"
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-archive-fill"></i>
              <div>
                {{ $t("sidebar9") }}
              </div>
            </router-link>
          </li>

          <!-- quotations -->
          <li>
            <router-link
              :to="{ name: 'quotations-index' }"
              :class="$route.name.includes('quotations') ? 'active' : ''"
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-archive-fill"></i>
              <div>
                {{ $t("sidebar23") }}
              </div>
            </router-link>
          </li>

          <!-- invoices -->
          <li>
            <router-link
              :to="{ name: 'invoices-index' }"
              :class="$route.name.includes('invoices') ? 'active' : ''"
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-paragraph"></i>
              <div>
                {{ $t("sidebar24") }}
              </div>
            </router-link>
          </li>

          <!-- expenses -->
          <li>
            <router-link
              :to="{ name: 'expenses-index' }"
              :class="$route.name.includes('expenses') ? 'active' : ''"
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-paragraph"></i>
              <div>
                {{ $t("sidebar10") }}
              </div>
            </router-link>
          </li>

          <!-- settings -->
          <li>
            <button
              @click="
                settings_display
                  ? (settings_display = false)
                  : ((settings_display = true), (security_display = false))
              "
              :to="{ name: 'settings-index' }"
              :class="$route.name.includes('settings') ? 'active' : ''"
              class="s-sidebar__nav-link text-end"
            >
              <i class="bi bi-gear-wide-connected"></i>
              <div class="justify-content-start">
                {{ $t("sidebar11") }}
              </div>
              <span class="">
                <i
                  v-if="!settings_display"
                  class="bi fs-6 bi-chevron-right ms-5 text-white"
                ></i>
                <i
                  v-if="settings_display"
                  class="bi fs-6 bi-chevron-down ms-5 text-white"
                ></i>
              </span>
            </button>
          </li>

          <div
            v-show="settings_display"
            class="ms-3 list-style-none overflow-auto"
          >
            <!-- societies -->
            <li>
              <router-link
                :to="{ name: 'societies-index' }"
                :class="$route.name.includes('societies') ? 'active' : ''"
                class="s-sidebar__nav-link dropdown-item"
              >
                <!-- <i class="bi bi-shield-fill-exclamation"></i> -->
                <div>
                  {{ $t("sidebar12") }}
                </div>
              </router-link>
            </li>

            <!-- customers -->
            <li>
              <router-link
                :to="{ name: 'customers-index' }"
                :class="$route.name.includes('customers') ? 'active' : ''"
                class="s-sidebar__nav-link dropdown-item"
              >
                <!-- <i class="bi bi-people"></i> -->
                <div>
                  {{ $t("sidebar13") }}
                </div>
              </router-link>
            </li>

            <!-- suppliers -->
            <li>
              <router-link
                :to="{ name: 'suppliers-index' }"
                :class="$route.name.includes('suppliers') ? 'active' : ''"
                class="s-sidebar__nav-link dropdown-item"
              >
                <!-- <i class="bi bi-person-lines-fill"></i> -->
                <div>
                  {{ $t("sidebar14") }}
                </div>
              </router-link>
            </li>

            <!-- items -->
            <li>
              <router-link
                :to="{ name: 'items-index' }"
                :class="$route.name.includes('items') ? 'active' : ''"
                class="s-sidebar__nav-link dropdown-item"
              >
                <!-- <i class="bi bi-inboxes-fill"></i> -->
                <div>
                  {{ $t("sidebar15") }}
                </div>
              </router-link>
            </li>

             <!-- sercies -->
            <li>
              <router-link
                :to="{ name: 'services-index' }"
                :class="$route.name.includes('services') ? 'active' : ''"
                class="s-sidebar__nav-link dropdown-item"
              >
                <!-- <i class="bi bi-inboxes-fill"></i> -->
                <div>
                  Services
                </div>
              </router-link>
            </li>

            <!-- items -->
            <li>
              <router-link
                :to="{ name: 'settings-index' }"
                :class="$route.name.includes('settings') ? 'active' : ''"
                class="s-sidebar__nav-link dropdown-item"
              >
                <!-- <i class="bi bi-inboxes-fill"></i> -->
                <div>
                  {{ $t("sidebar11") }}
                </div>
              </router-link>
            </li>
          </div>

          <!--raw-materials-->
          <!-- <li>
            <router-link
              :to="{ name: 'raw-materials-index' }"
              :class="$route.name.includes('raw-materials') ? 'active' : ''"
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-send-plus-fill"></i>
              <div>
                {{ $t("sidebar16") }}
              </div>
            </router-link>
          </li> -->

          <!-- productions  -->
          <li>
            <router-link
              :to="{ name: 'productions-index' }"
              :class="$route.name.includes('productions') ? 'active' : ''"
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-send-plus-fill"></i>
              <div>
                {{ $t("sidebar17") }}
              </div>
            </router-link>
          </li>

          <!--backup-->
          <li>
            <router-link
              :to="{ name: 'backups-index' }"
              :class="$route.name.includes('backups') ? 'active' : ''"
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-layer-backward"></i>
              <div>
                {{ $t("sidebar18") }}
              </div>
            </router-link>
          </li>

          <!-- securities -->
          <li>
            <button
              @click="
                security_display
                  ? (security_display = false)
                  : ((security_display = true), (settings_display = false))
              "
              :to="{ name: 'settings-index' }"
              :class="$route.name.includes('settings') ? 'active' : ''"
              class="s-sidebar__nav-link text-end"
            >
              <i class="bi bi-fingerprint"></i>
              <div class="justify-content-start">
                {{ $t("sidebar19") }}
              </div>
              <span class="">
                <i
                  v-if="!security_display"
                  class="bi fs-6 bi-chevron-right ms-5 text-white"
                ></i>
                <i
                  v-if="security_display"
                  class="bi fs-6 bi-chevron-down ms-5 text-white"
                ></i>
              </span>
            </button>
          </li>

          <div
            v-show="security_display"
            class="ms-3 list-style-none overflow-auto"
          >
            <!-- users -->
            <li>
              <router-link
                :to="{ name: 'users-index' }"
                :class="$route.name.includes('users') ? 'active' : ''"
                class="s-sidebar__nav-link dropdown-item"
              >
                <!-- <i class="bi bi-person-circle"></i> -->
                <div>
                  {{ $t("sidebar20") }}
                </div>
              </router-link>
            </li>
          </div>

          <div class="my-5"></div>
        </ul>
      </nav>

      <div
        class="posistion-fixed fixed-bottom"
        :style="{
          zIndex: '-1000 !important',
          width: '15em',
        }"
      >
        <a
          href="https://wa.me/212600302731"
          target="_blank"
          class="s-sidebar__nav-link bg-warning-faint rounded"
        >
          <i
            class="bi bi-headset fs-4 text-white"
            :style="{
              fontSize: '1.3em !important',
            }"
          ></i>
          <div class="text-white">
            {{ $t("sidebar21") }}
            <span class="ms-auto">V 0.1.3</span>
          </div>
          <br />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      settings_display: false,
      security_display: false,
      isSidebarOpen: false,
    };
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
  },
};
</script>
<style scoped>
.bi {
  font-size: 18px !important;
  margin: 0;
}
.bi-logo {
  font-size: 23px !important;
  margin: 0;
}
@media (max-width: 767px) {
  .bi {
    font-size: 32px !important;
    margin: 0;
  }
}
</style>
