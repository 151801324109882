<template>
  <div class="">
    <input
      type="text"
      list="navbarData"
      v-model="searchValue"
      @input="onValueChange(searchValue)"
      class="form-control bg-none rounded border-0 border p-0"
    />
    <datalist id="navbarData">
      <option
        v-for="(navbar, index) in navbarData"
        :key="index++"
        :value="navbar.name"
      >
        <i :class="`${navbar.icon}`"></i>
        {{ navbar.title }}
      </option>
    </datalist>
  </div>
</template>
<script>
import navbar from "../../data/navbar/index.json";
export default {
  data() {
    return {
      searchValue: "",
      navbarData: navbar,
    };
  },
  methods: {
    async onValueChange(value) {
      var $route = "";
      $route = this.navbarData.find((p) => p.name === value);
      if ($route !== "") {
        this.$router.push({ name: $route.name });
        this.searchValue = "";
      }
    },
  },
};
</script>
<style scoped>
.bi {
  font-size: 14px !important;
  margin: 0;
}
.bi-logo {
  font-size: 23px !important;
  margin: 0;
}
@media (max-width: 767px) {
  .bi {
    font-size: 32px !important;
    margin: 0;
  }
}
</style>
