<template>
  <div class="text-bar">
    <div class="animated-text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: "PSM : la solution de gestion commerciale complète et innovante",
    };
  },
};
</script>

<style>
.text-bar {
  width: 100%;
  height: 50px; /* Adjust the height as needed */
  background: none; /* Change the background color as needed */
  display: flex;
  align-items: center;
  overflow: hidden;
}

.animated-text {
  animation: slide 20s linear infinite; /* Adjust the animation duration as needed */
  white-space: nowrap;
  color: #fff; /* Change the text color as needed */
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
