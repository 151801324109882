import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "productions";
// initial state
const state = () => ({
  all: [],
  productions: [],
  items: [],
  production: {},
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getItems: (state) => {
    return state.items;
  },
  getProduction: (state) => {
    return state.production;
  },

  getProductions: (state) => {
    return state.productions;
  },
  totalPriceTTCItems: (state) => {
    return state.items.reduce(
      (acc, val) => acc + val.quantity * val.priceTTC,
      0
    );
  },

  totalQuantityItems: (state) => {
    return state.items.reduce((acc, val) => acc + val.quantity, 0);
  },
  numberItems: (state) => {
    return state.items.length;
  },
};

// actions
const actions = {
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let productions = response.data;
      commit("setProductions", productions);
    });
  },

  async getAllItems({ commit }) {
    await axios.get(api + "_items").then((response) => {
      commit("setItems", response.data);
    });
  },
  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let production = response.data;
      commit("setProduction", production);
    });
  },

  async storeItem({ commit, dispatch }, data) {
    await axios.post(api + "_items", data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;

      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        commit("setItems", response.data.data);
      }
    });
  },

  async store({ commit, dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        router.push({ name: "productions-index" });
      }
    });
  },

  async update({ commit }, data) {
    await axios
      .put(api + "/" + data.reference, data.production)
      .then((response) => {
        let message = response.data.message;
        let status = response.data.status;
        dispatch("notification/store", { status, message }, { root: true });

        if (status == "success") {
          commit("setProduction", production);
          router.push({
            name: "profile-production",
            params: {
              reference: production.reference,
            },
          });
        }
      });
  },

  async destroyItem({ commit, dispatch }, id) {
    await axios.delete(api + "_items/" + id).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        commit("setItems", response.data.data);
      }
    });
  },
};

// mutations
const mutations = {
  setProductions(state, productions) {
    state.all = productions;
    state.productions = productions;
  },

  setItems(state, items) {
    state.items = items;
  },
  setProduction(state, production) {
    state.production = production;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
