import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "suppliers";
// initial state
const state = () => ({
  all: [],
  suppliers: [],
  supplier: {},
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getSupplier: (state) => {
    return state.supplier;
  },

  getSuppliers: (state) => {
    return state.suppliers;
  },
};

// actions
const actions = {
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let suppliers = response.data;
      commit("setSuppliers", suppliers);
    });
  },
  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let supplier = response.data;
      commit("setSupplier", supplier);
    });
  },

  async destroy({ commit }, reference) {
    await axios.delete(api + "/" + reference).then((response) => {
      let suppliers = response.data;
      commit("setSuppliers", suppliers);
    });
  },

  async store({ commit, dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        router.push({
          name: "suppliers-index",
        });
      }
    });
  },

  async update({ dispatch }, data) {
    await axios.put(api + "/" + data.reference, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;

      if (status == "success") {
        dispatch("notification/store", { status, message }, { root: true });
        if (status == "success") {
          router.push({
            name: "suppliers-index",
          });
        }
      }
    });
  },
};

// mutations
const mutations = {
  setSuppliers(state, suppliers) {
    state.all = suppliers;
    state.suppliers = suppliers;
  },
  setSupplier(state, supplier) {
    state.supplier = supplier;
  },
  async search(state, value) {
    value = value.charAt(0).toUpperCase() + value.slice(1);
    console.log(value);
    state.suppliers = state.all.filter(function (supplier) {
      return (
        supplier.firstName.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        supplier.lastName.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        supplier.phone.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        supplier.reference.toUpperCase().indexOf(value.toUpperCase()) > -1
      );
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
