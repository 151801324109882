import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
var $ = require("jquery");

import VueI18n from "vue-i18n";

// Importing JSON files
import enLocale from "./locales/en.json";
import frLocale from "./locales/fr.json";
import arLocale from "./locales/ar.json";

import VueHtmlToPaper from "vue-html-to-paper";
import VueConfirmDialog from "vue-confirm-dialog";
import Notifications from "vue-notification";

let basePath = "http://localhost:8081";
const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    `./app.css`,
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true,
  windowTitle: window.document.title, // override the window title
};

Vue.use(VueHtmlToPaper, options);
Vue.use(VueConfirmDialog);
Vue.use(Notifications);
Vue.use(VueI18n);

import "./app.css";
import "./axios-interceptor"; // Import the interceptor file

import DashboardAdmin from "./layouts/DashboardAdmin.vue";
import Default from "./layouts/Default.vue";
import Login from "./layouts/Login.vue";

Vue.prototype.$userRole = store.getters.userRole;

import { toFixedWithSpace } from "./filters";
Vue.prototype.$toFixedWithSpace = toFixedWithSpace;
Vue.filter("toFixedWithSpace", toFixedWithSpace);

Vue.config.productionTip = false;

Vue.component("layout-dashboard", DashboardAdmin);
Vue.component("layout-default", Default);
Vue.component("layout-login", Login);

Vue.component("vueInternetChecker", require("vue-internet-checker"));
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);

window.axios = require("axios");
// axios.defaults.baseURL = "http://localhost:8081";
// axios.defaults.withCredentials = true;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const token = localStorage.getItem("token-psm-goodgroupe-app-1");
axios.defaults.headers.common["Authorization"] =
  "Bearer " + String(token).replaceAll('"', "");

const messages = {
  en: enLocale,
  fr: frLocale,
  ar: arLocale,
};

const i18n = new VueI18n({
  locale: "fr", // Set the default locale
  fallbackLocale: "fr", // Set the fallback locale in case the current locale is not found
  messages,
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
